















import Vue from "vue";
import TabList from "@/components/TabList.vue";
export default Vue.extend({
  components: { TabList },
  data: () => ({
    tab: {
      selectedTab: 0,
      tabTitle: "新闻公告",
      tabs: [
        { text: "新闻", path: "/article/news" },
        { text: "公告", path: "/article/notice" },
      ],
    },
  }),
  created() {
    console.log("page", this.$route.name);
    this.tab.selectedTab = this.$route.name == "noticeIndex" ||  this.$route.name == "noticeDetail" ? 1 : 0;
  },
});
